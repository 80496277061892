//USING THIS TO HAVE REM LIKE MANAGMENT OF HEADER-SIZES WHICH OFTEN HAVE A DIFFERENT FONT
const remCalc = function(size) {
  if (isNaN(size)) {
    size = parseInt(size);
  }
  return size / typography.size.replace('px', '') + 'rem';
};

//COLORS VARIABLES
const colors = {
  white: '#FFF',
  black: '#111',
  red: '#E82A2A',
  green: '#69a24c',
  yellow: '#FFCB37',
  font: '#1B223D',
  primary: '#383A42',
  primaryLight: '#383A42',
  primaryDark: '#383A42',
  secondary: '#333',
  secondaryDark: '#333',
  discount: '#b44e3c',
  border: '#DDD',
  borderLight: '#F1F1F1',
  borderDark: '#333',
  borderInput: '#AAA',
  lightgrey: '#f6f6f6',
  vanillaWhite: '#fbf8f4',
  grey: '#565558',
  error: '#ff3131',
  success: '#69a24c',
  greys: [
    '#F9F9F9',
    '#F1F1F1',
    '#D5D5D5',
    '#C4C4C4',
    '#9A9A9A',
    '#777777',
    '#3B3B3B'
  ]
};
colors.font = '#111';
colors.link = colors.secondary;
colors.buy = colors.primary;
colors.backgroundLight = '#EEE';
colors.backgroundDark = colors.primary;
colors.inStock = '#339600';
colors.outOfStock = colors.red;

//FONTS
const font = {
  primary: 'titillium',
  secondary: 'titillium',
  third: 'knockout',
  id: ''
};

const weights = {
  ultra: 100,
  light: 300,
  normal: 400,
  medium: 600,
  bold: 700
};

//TYPOGRAPHY
let typography = {
  fontFamily: font.primary,
  fontWeight: weights.normal,
  size: '15px',
  letterSpacing: '0px',
  paragraph: {
    fontSize: '1rem',
    lineHeight: '1.7em',
    marginBottom: '1rem'
  },
  header: {
    fontFamily: font.third,
    textTransform: 'uppercase',
    fontWeight: weights.normal,
    letterSpacing: '0px',
    secondFont: {
      fontFamily: font.secondary,
      fontWeight: weights.normal
    },
    secondColor: {
      color: colors.secondary
    }
  }
};

//HEADER FONTSIZES h1-h5 (sm & md & lg & xl)
typography.header.fontSize = [
  '__',
  [remCalc(26), remCalc(31), remCalc(41), remCalc(52)],
  [remCalc(20), remCalc(24), remCalc(28), remCalc(31)],
  [remCalc(18), remCalc(20), remCalc(21), remCalc(21)],
  [remCalc(15), remCalc(16), remCalc(17), remCalc(17)],
  [remCalc(14), remCalc(14), remCalc(14), remCalc(14)]
];

const config = {
  storeName: process.env.REACT_APP_SHOP_NAME || 'Stanley',
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/bookmarks'
    },
    blog: {
      path: process.env.REACT_APP_BLOG_PATH || '/stories',
      id: process.env.REACT_APP_BLOG_ID || 157,
      mainLevel: 1,
      menu: true
    },
    brand: {
      id: process.env.REACT_APP_BRAND_ID || 0
    },
    myPages: {
      copyAccesCode: false,
      showNewsletter: false,
      showMemberCode: false,
      showAccessCode: false
    },
    customCategory: {
      id: 0,
      fetchId: 0
    }
  },
  settings: {
    buyButtons: true,
    channelSelector: true,
    siteContentId: process.env.REACT_APP_SITECONTENT_ID || 51,
    useLogin: false
  },
  lipScore: {
    Norway: '96c5f9952b1d6ba99ef2a456',
    Sweden: 'ee79a3eb0b72d308d906539b'
  }
};

const general = {
  maxWidth: '1600px',
  maxWidthText: '1000px',
  pagePadding: {
    lg: '1rem',
    md: '1rem',
    sm: '1rem'
  },
  hallmarkBorder: '2px solid ' + colors.borderDark,
  radius: '3px'
};

//BUTTON
const button = {
  background: colors.primary,
  backgroundSecondary: colors.secondary,
  color: colors.white,
  colorSecondary: colors.white,
  fontFamily: font.primary,
  fontWeight: weights.medium,
  fontSize: typography.header.fontSize[4],
  ListFontSize: typography.header.fontSize[5],
  textTransform: 'uppercase',
  letterSpacing: '0.5px'
};

//AREAS

//TOPBAR
const topBar = {
  height: {
    lg: 40,
    md: 40,
    sm: 40,
    scroll: 0
  },
  color: colors.white,
  background: colors.primary,
  fontSize: '0.9rem',
  fontWeight: weights.normal,
  usp: {
    fontSize: '0.8rem',
    fontWeight: weights.normal
  }
};

//DESKTOP HEADER
const desktopHeader = {
  height: {
    lg: 70,
    md: 70,
    scroll: 70
  },
  search: {
    height: {
      lg: 30,
      md: 30
    }
  },
  logo: {
    width: {
      lg: '200px',
      xl: '200px',
      scroll: '200px'
    }
  },
  background: colors.white,
  color: colors.font,
  nav: {
    fontSize: ['0.9rem', '1rem'],
    fontWeight: weights.normal,
    padding: '0.8rem',
    barHoverColor: colors.secondary,
    subMenu: {
      lv2: {
        fontSize: '1.1rem'
      },
      lv3: {
        fontSize: '0.9rem'
      }
    },
    height: {
      lg: 70,
      md: 70,
      scroll: 70
    },
    useSubMenuImage: true
  }
};

//MOBILE HEADER
const mobileHeader = {
  height: {
    xs: 60,
    sm: 60,
    scroll: 60
  },
  search: {
    height: {
      xs: 60,
      sm: 60
    }
  },
  logo: {
    width: '120px'
  },
  background: colors.white,
  color: colors.font,
  nav: {
    lv1: {
      fontSize: remCalc(17),
      fontWeight: typography.header.fontWeight,
      fontFamily: typography.header.fontFamily,
      lineHeight: '3.4em',
      textTransform: 'uppercase'
    },
    lv2: {
      fontSize: '0.9rem',
      lineHeight: '3.2em'
    },
    lv3: {
      fontSize: '0.8rem',
      lineHeight: '3em'
    },
    activeColor: colors.secondary,
    selectedColor: colors.secondary
  }
};

//HEADER HEIGHTS
const header = {
  height: {
    lg: topBar.height.lg + desktopHeader.height.lg,
    md: topBar.height.md + desktopHeader.height.md,
    sm: topBar.height.md + mobileHeader.height.sm,
    xs: topBar.height.md + mobileHeader.height.xs,
    scroll: topBar.height.scroll + desktopHeader.height.scroll
  },
  scrollBreak: 50
};

//LOGO
const logo = {
  size: 400,
  aspect: '230:80'
};

//CAMPAIGN BAR
const campaignBar = {
  primary: {
    background: colors.backgroundLight,
    color: colors.font
  },
  secondary: {
    background: colors.secondary,
    color: colors.white
  }
};

//FOOTER
const footer = {
  background: colors.secondary,
  color: colors.white,
  logoBackground: colors.white,
  jetshopBackground: colors.white,
  jetshopColor: colors.font,
  imageAspect: '4:3'
};

//START PAGE
const startPage = {
  headers: {
    fontFamily: typography.header.fontFamily,
    fontWeight: weights.normal,
    textTransform: 'uppercase',
    fontSize: [
      '__',
      [remCalc(40), remCalc(50), remCalc(60), remCalc(71)],
      [remCalc(26), remCalc(31), remCalc(41), remCalc(52)],
      [remCalc(20), remCalc(24), remCalc(28), remCalc(31)],
      [remCalc(18), remCalc(20), remCalc(21), remCalc(21)],
      [remCalc(15), remCalc(16), remCalc(17), remCalc(17)],
      [remCalc(14), remCalc(14), remCalc(14), remCalc(14)]
    ]
  },
  iconBar: {
    background: colors.primary,
    color: colors.white
  },
  row: {
    background: {
      primary: colors.primary,
      secondary: colors.secondary
    },
    color: {
      primary: colors.white,
      secondary: colors.white
    },
    noImage: {
      backgroundLight: colors.backgroundLight,
      backgroundDark: colors.backgroundDark
    }
  },
  readMore: {
    color: colors.font,
    fontFamily: typography.header.fontFamily,
    fontWeight: typography.header.fontWeight
  },
  cta: {
    fontSize: button.fontSize,
    color: colors.primary,
    background: colors.white,
    letterSpacing: button.letterSpacing,
    border: '1px solid #707070',
    fontFamily: button.fontFamily,
    fontWeight: button.fontWeight,
    textTransform: 'uppercase',
    borderRadius: '15px',
    hover: {
      color: colors.white,
      background: colors.secondary,
      border: '1px solid #707070'
    },
    secondary: {
      color: colors.white,
      background: colors.secondary,
      border: 'none',
      hover: {
        color: colors.white,
        background: colors.primary,
        border: 'none'
      }
    },
    third: {
      color: colors.white,
      background: colors.primary,
      border: '1px solid white',
      hover: {
        color: colors.white,
        background: colors.secondary,
        border: '1px solid white'
      }
    },
    fourth: {
      color: colors.primary,
      background: colors.white,
      border: 'none',
      hover: {
        color: colors.white,
        background: colors.primary,
        border: 'none'
      }
    }
  }
};

//CATEGORY PAGE
const categoryPage = {
  subcategories: {
    fontFamily: typography.header.fontFamily,
    fontWeight: weights.normal
  },
  filter: {
    markerColor: colors.buy,
    hoverColor: colors.buy,
    borderColor: colors.borderDark,
    name: {
      tag: 'p'
    }
  }
};

//PRODUCT GRID
const productGrid = {
  product: {
    imageAspect: '4:3',
    background: '#FFF',
    padding: ['1rem', '1rem', '1rem'],
    border: 'none',
    discount: true,
    centerAlign: false,
    name: {
      tag: 'h4',
      fontFamily: font.primary,
      fontWeight: weights.normal
    }
  }
};

const price = {
  fontFamily: typography.fontFamily,
  fontWeight: weights.normal,
  normal: {
    color: colors.font,
    fontSize: typography.header.fontSize[4]
  },
  new: {
    color: colors.discount,
    fontSize: typography.header.fontSize[4]
  },
  old: {
    color: colors.greys[5],
    fontSize: typography.header.fontSize[5]
  }
};

//PRODUCT PAGE
const productPage = {
  name: {
    fontFamily: typography.header.fontFamily,
    fontWeight: typography.header.fontWeight
  },
  price: {
    fontWeight: price.fontWeight,
    normal: {
      fontSize: typography.header.fontSize[3]
    },
    new: {
      fontSize: typography.header.fontSize[3]
    },
    old: {
      fontSize: typography.header.fontSize[4]
    }
  },
  imageAspect: '1:1',
  buyButton: {
    background: colors.buy,
    color: colors.white
  },
  usp: {
    fontWeight: weights.bold
  },
  tabs: {
    barActiveColor: colors.primary,
    header: {
      tag: 'h4',
      fontFamily: typography.header.fontFamily,
      fontWeight: typography.header.fontWeight
    }
  },
  productList: {
    headerColor: colors.primary,
    subHeaderColor: colors.secondary
  }
};

const favourites = {
  colorActive: colors.buy
};

//EXPORT COMPLETE THEME
export const stanleyTheme = {
  config,
  font,
  weights,
  typography,
  colors,
  general,
  button,
  topBar,
  desktopHeader,
  mobileHeader,
  header,
  logo,
  campaignBar,
  footer,
  startPage,
  categoryPage,
  productGrid,
  price,
  productPage,
  favourites
};
