import { theme } from '../Theming/Theming';

const useLipScore = selectedChannel => {
  const lipscore = theme.config.lipScore;
  if (!lipscore) return null;

  if (
    lipscore &&
    typeof lipscore === 'object' &&
    typeof lipscore[selectedChannel.name] !== 'undefined'
  ) {
    return lipscore[selectedChannel.name];
  }

  return null;
};

export default useLipScore;
